import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { Utils } from '@klickdata/core/util';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class HttpLocaleInterceptor implements HttpInterceptor {
    constructor(@Inject(LOCALE_ID) protected localeId: string, protected auth: AuthService) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.auth.isValid(true).pipe(
            first(),
            switchMap((isAuthorized) => {
                let modifiedReq = req.clone({
                    setHeaders: {
                        'Accept-Language': this.localeId,
                        'Nk3-Platform': this.auth.getNK3PlatformValue(),
                        'X-Socket-ID': this.auth.socketId,
                    },
                });

                if (!isAuthorized) {
                    const subdomain = Utils.getSubdomain();
                    if (subdomain && req.method === 'GET') {
                        const url = new URL(req.url);
                        url.searchParams.set('short_name', subdomain);
                        modifiedReq = modifiedReq.clone({
                            url: url.toString(),
                        });
                    }
                }

                return next.handle(modifiedReq);
            })
        );
    }
}
