import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { ResourceData } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';

@Injectable({
    providedIn: 'root',
})
export class ResourceDetailsViewGuard implements CanActivate {
    constructor(
        protected authService: AuthService,
        protected router: Router,
        private error: HttpErrorService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        const resource$ = this.fetchResource(route.params.id);
        const user$ = this.authService.getUser().pipe(startWith(new User({ role_value: 'guest' })));
        return combineLatest([resource$, user$]).pipe(switchMap(([res, user]) => this.checkAuthorization(res, user)));
    }

    private fetchResource(resourceId: number): Observable<ResourceData | null> {
        return this.builder
            .get<ResourceData>(`${this.config.config.apiUrl}resources/${resourceId}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    private checkAuthorization(res: ResourceData, user: User): Observable<boolean> {
        if (user?.id) {
            if (!user.hasViewPrivilege(res)) {
                this.router.navigate(['/unauthorised']);
                return of(false);
            }
        } else if (!res) {
            this.authService.openLoginPage(false, this.router);
            return of(false);
        }

        return of(true);
    }
}
